import { Injectable } from '@angular/core';
import { EnvConfiguration } from 'libs/angular/sv-common/src/lib/env/env.configuration';
import {
  ExtractedMedia,
  PageMappingControllerService,
  PublicPageControllerService,
} from '@sv/swagger/riva-api';
import { Router } from '@angular/router';
import { TargetSystem } from '@sv/sv-common/lib/env/target-system';
import InternalLinkTypeEnumEnum = ExtractedMedia.InternalLinkTypeEnumEnum;

@Injectable()
export class EnvService {
  constructor(
    private envConfiguration: EnvConfiguration,
    private pageMappingService: PageMappingControllerService,
    private publicPageControllerService: PublicPageControllerService,
    private router: Router
  ) {}

  // TODO DS - Consolidate with frLinkLoader
  openInternalLink(
    entityId: string,
    entityType: string | InternalLinkTypeEnumEnum,
    openInNewTab: boolean,
    projectSlugName?: string | null
  ) {
    switch (entityType) {
      case 'PROJECT':
        this.callLink(
          this.envConfiguration.links?.RIVA + '/home?project-id=' + entityId,
          openInNewTab
        );
        break;
      case 'LEARNING_PAGE':
        // TODO DS: Create Preview for user
        // this.callLink(this.envConfiguration.links?.RIVA_LP + "/" + entityId, openInNewTab);
        break;
      case 'TASK_GROUP':
        this.callLink(
          `${this.envConfiguration.links?.RIVA}/${projectSlugName}/sus/tasks/task-group/${entityId}`,
          openInNewTab
        );
        break;
      case 'LEARNING_CONTAINER':
        // TODO DS: Create Preview for user
        // this.callLink(this.envConfiguration.links?.RIVA_LP + "/" + entityId, openInNewTab);
        break;
      case InternalLinkTypeEnumEnum.PublicPage:
        this.publicPageControllerService
          .getPublicPageById(entityId)
          .subscribe((publicPageDetailDto) => {
            this.callLink(
              `${this.envConfiguration.links?.RIVA}/public/${publicPageDetailDto.publicPageContainerName}/shortcodes/${publicPageDetailDto.shortCodes[0]}`,
              openInNewTab
            );
          });
        break;
      case 'USER':
        // TODO DS: Create Preview for user
        break;
      case 'PAGE':
        this.pageMappingService
          .getPageMappingInfoById(entityId)
          .subscribe((val) => {
            this.callLink(
              `${this.envConfiguration.links?.RIVA}/${val.project.slug}/lp/material/${val.learningMaterial.id}/${val.learningUnit.id}/${val.learningUnitTab.id}/${val.pageId}`,
              openInNewTab
            );
          });
        break;
      default:
        // this.toastrService.info('Internal links for \'' + entityType + "\' are not implemented yet.");
        break;
    }
  }

  getTargetSystem(): TargetSystem {
    return (
      this.envConfiguration.options?.targetSystem || TargetSystem.DEVELOPMENT
    );
  }

  private callLink(url: string, openInNewTab: boolean) {
    openInNewTab ? window.open(url, '_blank') : this.router.navigateByUrl(url);
  }
}
